<template>
  <DefaultLayout title="闪现一下">
    <NewGameLoading
      v-if="pageLoading"
    />
    <PostError
      v-if="postError"
      :custom-header="false"
      :text="postError"
    />
    <div
      v-else
      class="page-game-introduce"
    >
      <!-- <NewGameHeader
        v-if="isApp"
        :auto-back="!isFromReservation"
        @back="$router.back()"
        title="新游介绍"
      /> -->
      <GroupOrGameHeader
        v-if="isApp"
        :is-from-group="false"
        :show-new-game-header="true"
        :auto-back="!isFromReservation"
        @back="$router.back()"
        :menu-status="false"
        title-content="新游介绍"
      />
      <NewGameShow
        :banners="allGameDetail.banners"
        :game-info="gameInfo"
      />
      <NewGameInfo
        :game-info="allGameDetail"
        :is-game-introduce="true"
        class="page-game-introduce-info"
      />
      <GameSubscribeDialog
        v-model="dialogShow"
      />
      <NewGameIntro
        :game-info="gameInfo"
      />
      <NewGameBanner :game-code="gameCode" />
      <div class="page-game-introduce-line" />
      <NewGameVideo
        :group-id="groupId"
        :game-code="gameCode"
        :play-info-list="playInfoList"
        @videoClick="btnClick(arguments)"
      />

      <NewGamePost
        :rcmd-posts="rcmdPosts"
        :group-name="gameInfo.name"
        :game-code="gameCode"
        class="page-game-introduce-post"
      />
      <div
        v-if="true"
        class="page-game-introduce-more"
      >
        <Button
          v-if="groupId !== '0'"
          :width="192"
          :type="'primary'"
          @click="onGroupBtnClick"
          class="btn"
        >
          进圈查看更多精彩内容
        </Button>
      </div>
      <div
        v-if="videoShow"
        class="page-game-introduce-video"
      >
        <div
          @click="onCloseVideoHandler"
          class="page-game-introduce-video-close"
        />
        <VideoPlayer
          v-if="playInfo.type === 'txv'"
          :play-info="playInfo"
          class="page-game-introduce-video-show-txv"
        />
        <TXCloudPlayer
          v-else
          :banner-info="playInfo"
          :real-index="realIndex"
          :swiperSlideIndex="index + 1"
          :autoplay="true"
          :muted="false"
          class="page-game-introduce-video-show"
        />
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import { mapState } from 'vuex';
import DefaultLayout from '@/layouts/default/index.vue';
import NewGameLoading from '@/component/new-game-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
import GroupOrGameHeader from '@/component/group-or-game-header/index.vue';
import NewGameShow from './component/new-game-show/index.vue';
import NewGameInfo from '@/component/new-game-info/index.vue';
import Button from '@/ui/button';
import newGameApi from '@/api/newgame';
import VideoPlayer from '@/component/video-player/index.vue';
import TXCloudPlayer from '@/component/tx-cloud-player/index.vue';
import reportApi from '@/api/report';
import { receiveGlobalNotification } from '@/jsbridge';

const NewGameVideo = () => import('./component/new-game-video/index.vue');
const NewGameIntro = () => import('./component/new-game-intro/index.vue');
const NewGamePost = () => import('./component/new-game-post/index.vue');
const GameSubscribeDialog = () => import ('@/component/game-subscribe-dialog/index.vue');
const NewGameBanner = () => import('./component/new-game-banner/index.vue');
export default {
  name: 'NewGameIntroduce',
  components: {
    DefaultLayout,
    NewGameLoading,
    PostError,
    GroupOrGameHeader,
    NewGameShow,
    NewGameInfo,
    GameSubscribeDialog,
    NewGameIntro,
    NewGameVideo,
    NewGamePost,
    Button,
    VideoPlayer,
    TXCloudPlayer,
    NewGameBanner,
  },
  data() {
    return {
      playInfo: {},
      dialogShow: false,
      videoShow: false,
      postError: false,
      pageLoading: true,
      gameInfo: null,
      allGameDetail: null,
      gameCode: null,
      groupId: '88888924',
      wonderfulVideoPosts: null,
      playInfoList: [],
      index: null,
      realIndex: null,
      rcmdPosts: null,
      isFromReservation: false,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
    }),
  },
  created() {
    this.gameCode = this.$route.query.gameCode ?? '';
  },
  mounted() {
    this.$store.dispatch('user/getUserInfo');

    this.pageLoading = true;
    this.isFromReservation = this.$route.query.isFromReservation ?? false;
    this.getNewGameDetail();
    this.registerEvent();
    // operid: '2902000010201',
    reportApi({
      pageid: '2902',
      moduleid: 1,
      eventtype: 2,
      extendid: 1,
      gameCode: this.gameCode,
    }, '新游介绍详情页曝光');
  },
  provide() {
    return {
      onIsWantHandler: (...arg) => this.onIsWantHandler(...arg),
      dialogShowHandler: (...arg) => this.dialogShowHandler(...arg),
    };
  },
  methods: {
    btnClick(argument) {
      [this.playInfo, this.index, this.realIndex, this.videoShow] = argument;
    },

    handleVideoList(wonderfulVideoPosts) {
      wonderfulVideoPosts.forEach((item) => {
        if (item.videoInfo.source === 2) {
          this.playInfoList.push({ type: 'txv', vid: item.videoInfo.vid, coverImg: item.videoInfo.coverUrl, title: item.title });
        }
        if (item.videoInfo.source === 1) {
          this.playInfoList.push({ type: 'txy', videoUrl: item.videoInfo.detail[0].source.replace('http://', 'https://'),
            coverImg: item.videoInfo.coverUrl,  title: item.title, bannerType: 2 });
        }
      });
    },

    onGroupBtnClick() {
      this.$linkToGroup(this.groupId);
      // operid: '2902000950301',
      reportApi({
        pageid: '2902',
        moduleid: 95,
        eventtype: 3,
        extendid: 1,
        gameCode: this.gameCode,
      }, '新游介绍详情页-进圈按钮点击');
    },
    onCloseVideoHandler() {
      this.videoShow = false;
    },
    // 监听成功加入圈子事件 刷新页面
    registerEvent() {
      receiveGlobalNotification('g_join_group_id', () => {
        this.getNewGameDetail();
      });
      receiveGlobalNotification('g_quit_group_id', () => {
        this.getNewGameDetail();
      });
    },
    getNewGameDetail() {
      newGameApi.getNewGameDetail(this.gameCode)
        .then((res) => {
          this.pageLoading = false;
          if (res.ret === 0) {
            this.gameInfo = res.gameInfo;
            this.groupId = res.groupId;
            this.allGameDetail = res;
            this.rcmdPosts = res.rcmdPosts;
            const { wonderfulVideoPosts } = res;
            this.handleVideoList(wonderfulVideoPosts);
          } else {
            this.pageError = '内容加载失败，换个姿势刷新试试';
          }
        })
        .catch((err) => {
          this.pageLoading = false;
          this.postError = '内容加载失败，换个姿势刷新试试';
          this.logger.err('getNewGameDetail err', err);
        });
    },
    onIsWantHandler() {
      this.allGameDetail.subscribeStats.selfSubscribe = !this.allGameDetail.subscribeStats.selfSubscribe;
    },
    dialogShowHandler(isShow) {
      this.dialogShow = isShow;
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
