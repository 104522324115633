<template>
  <div class="com-new-game-loading">
    <img
      src="./img/icon-loading.gif"
      alt=""
      class="com-new-game-loading-img"
    >
  </div>
</template>
<script>
export default {
  name: 'NewGameLoading',
};
</script>
<style lang="scss" scoped>
.com-new-game-loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  font-size: 20px;
  color: #303133;
  z-index: 999;
  &-img {
    height: 17px;
    width: 17px;
    background-size: 100%;
		animation: rotation 3s linear infinite;
	@keyframes rotation{
		from {transform: rotate(0deg)}
		to {transform: rotate(360deg)}
    }
  }
}
</style>
