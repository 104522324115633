<template>
  <div
    class="tx-cloud-player"
  >
    <video
      ref="videoRef"
      :id="txCloudContainer"
      :style="customStyle"
      :muted="muted"
      :autoplay="autoplay"
      :class="{'tx-cloud-player-ios':isBorderRadius}"
      class="tx-cloud-player-video"
      preload="auto"
      playsinline
      webkit-playsinline
    />
  </div>
</template>


<script>
import { logger } from '@/util/logger';
import { getScript, loadCssLink } from '@/util/util';
// Hls 要比web.sdk 超前加载 不然第一次加载偶现Hls is not defined问题 写在getTencentCloudPlayer函数里也没有用还是会偶现
getScript(`${window.location.protocol}//web.sdk.qcloud.com/player/tcplayer/release/v4.5.1/libs/hls.min.0.13.2m.js`);
// css 提前加载 原因：部分ios机 暂停图标飞入视频封面
loadCssLink('//web.sdk.qcloud.com/player/tcplayer/release/v4.5.1/tcplayer.min.css');

const getTencentCloudPlayer = (retry = false) => new Promise(((resolve, reject) => {
  if (window.TCPlayer && window.Hls) {
    resolve();
  } else {
    const p2 = getScript(`${window.location.protocol}//web.sdk.qcloud.com/player/tcplayer/release/v4.5.1/tcplayer.v4.5.1.min.js`);
    const p1 = Promise.resolve();
    p1
      .then(() => p2)
      .then(() => {
        if (window.TCPlayer && window.Hls) {
          resolve();
        } else if (!retry) {
          // 重试加载一次
          getTencentCloudPlayer(true);
        } else {
          reject();
        }
      });
  }
  return true;
}));
export default {
  name: 'TXCloudPlayer',
  components: {
  },
  props: {
    bannerInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    swiperSlideIndex: {
      type: Number,
      default: -1,
    },
    realIndex: {
      type: Number,
      default: -1,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: true,
    },
    currentTimeLast: {
      type: Number,
      default: 0,
    },
    isBorderRadius: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    this.txCloudPlayer = '';

    return {
      customStyle: {},
      txCloudContainer: `tx-cloud-container${this.swiperSlideIndex >= 0 ? this.swiperSlideIndex : ''}`,
    };
  },
  watch: {
    realIndex() {
      if (this.realIndex !== this.swiperSlideIndex && this.txCloudPlayer) {
        this.txCloudPlayer.pause();
      }
    },
    bannerInfo(newVal) {
      if (newVal?.bannerType === 2) {
        this.initVideoPlayer();
      }
    },
  },
  mounted() {
    this.customStyle = {
      width: '100%',
      height: '100%',
    };
    // 兼容ios 视频borderRadius无效
    if (this.isBorderRadius) {
      this.customStyle = {
        ...this.customStyle,
        borderRadius: '16px',
        overflow: 'hidden' };
    }
    // 采用TCPlayer(页面引入web.SDK)
    if (this.bannerInfo?.bannerType === 2) this.initVideoPlayer();
  },
  destroyed() {
    if (this.txCloudPlayer) {
      this.$emit('setCurrentTimeLast', parseInt(this.txCloudPlayer.currentTime(), 10));
      this.txCloudPlayer.dispose();
    }
  },
  methods: {
    initVideoPlayer() {
      getTencentCloudPlayer().then(() => {
        this.$nextTick(() => {
          const { videoRef } = this.$refs;
          if (videoRef) {
            if (this.txCloudPlayer) {
              this.txCloudPlayer.src(this.bannerInfo.videoUrl);
              this.txCloudPlayer.poster(this.bannerInfo.coverImg);
            } else {
              const Button = window.TCPlayer.getComponent('Button');
              const BigPlayButton = window.TCPlayer.getComponent('BigPlayButton');
              BigPlayButton.prototype.createEl = function () {
                const el = Button.prototype.createEl.call(this);
                const imgHtml = '<svg width="100%" height="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_566_14335)"><path opacity="0.7" fill-rule="evenodd" clip-rule="evenodd" d="M100 50C100 22.39 77.61 0 50 0C22.39 0 0 22.39 0 50C0 77.61 22.39 100 50 100C77.61 100 100 77.61 100 50Z" fill="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M40.0365 33.8086C38.9816 34.4309 38.3333 35.5879 38.3333 36.8324V66.0011C38.3333 66.8373 38.7687 67.6054 39.4655 68.0234C40.172 68.4318 41.0429 68.4415 41.7397 68.0234L66.6203 53.4391C67.6752 52.8168 68.3333 51.6598 68.3333 50.4056C68.3333 49.161 67.6752 48.004 66.6203 47.3817L43.4526 33.7989C42.3978 33.1766 41.0913 33.1766 40.0365 33.8086Z" fill="white"/></g><defs><clipPath id="clip0_566_14335"><rect width="100" height="100" fill="white"/></clipPath></defs></svg>';
                el.appendChild(window.TCPlayer.dom.createEl('div', {
                  className: 'vjs-button-icon',
                  innerHTML: imgHtml,
                }));
                return el;
              };
              this.txCloudPlayer = window.TCPlayer(this.txCloudContainer, {});
              this.txCloudPlayer.src(this.bannerInfo.videoUrl.replace('http://', 'https://'));
              this.txCloudPlayer.poster(this.bannerInfo.coverImg.replace('http://', 'https://'));
              this.txCloudPlayer.currentTime(this.currentTimeLast);
              this.txCloudPlayer.on('error', (err) => {
                logger.info('getTencentCloudPlayer error', err);
              });
            }
          }
        });
      });
    },
  },

};
</script>
<style  lang="scss" scoped src='./scss/index.scss'></style>
