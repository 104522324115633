<template>
  <div
    class="com-game-show"
  >
    <div class="com-game-show-content">
      <div class="swiper-container gallery-top">
        <ul class="com-game-show-content-video swiper-wrapper">
          <li
            v-for="(item, index) in bannerInfoList"
            :key="index"
            :style="{height:`${height}px`}"
            class="com-game-show-content-video-item swiper-slide"
          >
            <div
              v-if="item.bannerType===2"
              class="com-game-show-video"
            >
              <TXCloudPlayer
                :banner-info="bannerInfoList[0]"
                :real-index="activeIndex"
                :swiper-slide-index="index"
                :is-border-radius="true"
                :autoplay="false"
                :style="{height:`${height}px`}"
                class="com-game-show-video-show"
              />
            </div>
            <img
              v-else
              :src="replaceHttps(item.coverImg)"
              class="com-game-show-content-video-item-pic"
              alt="picture"
            >
          </li>
        </ul>
      </div>
      <div class="swiper-container gallery-thumbs">
        <ul class="com-game-show-content-list swiper-wrapper">
          <li
            v-for="(item, index) in bannerInfoList"
            :key="index"
            class="com-game-show-content-list-item swiper-slide"
          >
            <img
              :src="replaceHttps(item.coverImg)"
              :class="{'com-game-show-content-list-active': index===activeIndex}"
              class="com-game-show-content-list-item-pic"
              alt="picture"
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TXCloudPlayer from '@/component/tx-cloud-player/index.vue';
import Swiper from 'swiper';
import jsbridge from '@/jsbridge';

export default {
  name: 'NewGameShow',
  components: {
    TXCloudPlayer,
  },
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
    gameInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      galleryTop: null,
      galleryThumbs: null,
      activeIndex: 0, // 如果有视频（只有一个） 默认是在第一位 传入为0 没有视频
      isHaveVideo: false,
      showImageList: [], // 图片查看器列表(字段width,height,url)
      bannerInfoList: [], // 里面有视频+图片
      isBorderRadis: this.$store.state.isIOS, // 兼容ios 视频borderRadius无效
      height: 0,
      width: 0,
    };
  },
  created() {
    this.width = document.documentElement.clientWidth;
    this.height = (this.width - 36) * 380 / 678;
    const videoBanner = this.banners?.find(item => item.bannerType === 2 && item.videoUrl);
    const { pictureList, pictureInfoList } = this.gameInfo;
    this.showImageList = pictureInfoList.map((item, index) => {
      this.bannerInfoList.push({ bannerType: 1, coverImg: pictureList[index] });
      return {
        width: item.width,
        height: item.height,
        url: pictureList[index],
      };
    });
    if (videoBanner) {
      this.isHaveVideo = true;
      this.bannerInfoList.unshift(videoBanner);
    }
  },
  mounted() {
    this.initSwiper();
  },

  methods: {
    initSwiper() {
      const vueThis = this;
      this.galleryThumbs = new Swiper('.com-game-show-content .gallery-thumbs', {
        // spaceBetween: 6,
        slidesPerView: 5,
        setWrapperSize: true,
        // freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,

      });
      this.galleryTop = new Swiper('.com-game-show-content .gallery-top', {
        spaceBetween: 20,
        slidesPerView: 1,
        width: this.width - 36,
        setWrapperSize: true,
        thumbs: {
          swiper: this.galleryThumbs,
        },
        on: {
          click() {
            // 有图片 放在图片查看器里
            if (vueThis.isHaveVideo && vueThis.activeIndex === 0) return;
            const showIndex = vueThis.isHaveVideo ? vueThis.activeIndex - 1 : vueThis.activeIndex;
            vueThis.showImageViewer(showIndex);
          },
          slideNextTransitionEnd() {
            vueThis.activeIndex = this.activeIndex;
          },
          slidePrevTransitionEnd() {
            vueThis.activeIndex = this.activeIndex;
          },
        },

      });
    },
    showImageViewer(showIndex) {
      // images 结构：width height,url
      if (this.$compareVersion('1.3') > -1) {
        this.showImageList.forEach((item) => {
          // 使用tgcimg协议
          // eslint-disable-next-line no-param-reassign
          item.url = this.$tgcimg(item.url);
        });
        jsbridge.core.call('ui/image_preview', {
          index: showIndex, images: this.showImageList,
        });
      } else {
        this.$toast('请升级至最新版本查看图片');
      }
    },
    replaceHttps(url) {
      return url.replace('http://', 'https://');
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
